import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'

const apiClient = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api`,
	timeout: 1000 * 20,
})

apiClient.interceptors.request.use((request) => {
	const accessToken = store.get('accessToken')
	if (accessToken) {
		request.headers = {
			...request.headers,
			Authorization: `Bearer ${accessToken}`,
			accesstoken: accessToken,
			'Access-Control-Allow-Origin': true,
		}
	}
	return request
})

// apiClient.interceptors.response.use(undefined, (error) => {
// 	// Errors handling
// 	const { response } = error
// 	const { data } = response
// 	if (data) {
// 		notification.warning({
// 			message: data.message || 'Error no determinado',
// 		})
// 	}
// })

export default apiClient
