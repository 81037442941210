import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import utilities from '@/services/utilities'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	// mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: 'dashboard',
			component: MainLayout,
			meta: {
				authRequired: true,
				hidden: true,
			},
			children: [
				// Dashboards
				{
					path: '/dashboard',
					meta: {
						title: 'Dashboard',
					},
					component: () => import('./views/dashboard/alpha'),
				},
				// Dirección
				{
					path: '/direccion',
					meta: {
						title: 'Dirección',
					},
					component: () => import('./views/new/direccion'),
				},
				// Ingresos
				{
					path: '/ingresos',
					meta: {
						title: 'Ingresos',
					},
					component: () => import('./views/new/ingresos'),
				},
				// Egresos
				{
					path: '/egresos',
					meta: {
						title: 'Egresos',
					},
					component: () => import('./views/new/egresos'),
				},
				// Almacén
				{
					path: '/almacen',
					meta: {
						title: 'Almacén',
					},
					component: () => import('./views/new/almacen'),
				},
				// Taller
				{
					path: '/taller',
					meta: {
						title: 'Taller',
					},
					component: () => import('./views/new/taller'),
				},
				// Clientes
				{
					path: '/clientes',
					meta: {
						title: 'Clientes',
					},
					component: () => import('./views/new/clientes'),
				},
				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				{
					path: '/widgets/charts',
					meta: {
						title: 'Widgets / Charts',
					},
					component: () => import('./views/widgets/charts'),
				},
				// Ordenes de compra
				{
					path: '/purchase-orders',
					meta: {
						title: 'Administración / Ordenes de Compra',
					},
					component: () => import('./views/purchase-orders'),
				},
				{
					path: '/purchase-orders/create',
					meta: {
						title: 'Administración / Ordenes de Compra / Nueva ODC',
					},
					component: () => import('./views/purchase-orders/details'),
				},
				{
					path: '/purchase-orders/:id',
					meta: {
						title: 'Administración / Ordenes de Compra / Detalle ODC',
					},
					component: () => import('./views/purchase-orders/details'),
				},
				{
					path: '/operations/cashflow',
					meta: {
						parent: 'Operaciones',
						title: 'Registro de gastos en efectivo',
					},
					component: () => import('./views/operations/cashflow'),
				},
				// Suppliers
				{
					path: '/catalogs/suppliers',
					meta: {
						title: 'Proveedores',
					},
					component: () => import('./views/suppliers'),
				},
				{
					path: '/catalogs/supplier/:id',
					meta: {
						parent: 'Proveedores',
						title: 'Detalle de Registro',
					},
					component: () => import('./views/suppliers/details'),
				},
				//Quote
				{
					path: '/operations/quote',
					meta: {
						title: 'Administración / Cotizador',
					},
					component: () => import('./views/quote'),
				},
				{
					path: '/branch-offices',
					meta: {
						title: 'Administración / Sucursales',
					},
					component: () => import('./views/branch-offices'),
				},

				{
					path: '/catalogs/service-administration',
					meta: {
						title: 'Administración / Administración de Servicios',
					},
					component: () => import('./views/service-administration'),
				},
				{
					path: '/quotations/services',
					meta: {
						title: 'Administración / Servicios',
					},
					component: () => import('./views/price-tab'),
				},
				{
					path: '/catalogs/motorcycle-brands',
					meta: {
						title: 'Administración / Marca Moto',
					},
					component: () => import('./views/motorcycle-brands'),
				},
				{
					path: '/catalogs/motorcycle-models',
					meta: {
						title: 'Administración / Modelo Moto',
					},
					component: () => import('./views/motorcycle-models'),
				},
				{
					path: '/warehouse/products',
					meta: {
						title: 'Inventario / Productos',
					},
					component: () => import('./views/products'),
				},
				{
					path: '/warehouse/products/create',
					meta: {
						title: 'Inventario / Productos / Nuevo registro',
					},
					component: () => import('./views/products/details'),
				},
				{
					path: '/warehouse/products/:id',
					meta: {
						title: 'Inventario / Productos / Detalle de productos',
					},
					component: () => import('./views/products/details'),
				},
				// POS Dashboard
				{
					path: '/pos/dashboard',
					meta: {
						title: 'Punto de venta / Dashboard',
					},
					component: () => import('./views/pos/dashboard'),
				},
				// POS
				{
					path: '/pos',
					meta: {
						title: 'Punto de venta',
					},
					component: () => import('./views/pos'),
				},
				// Clientes
				{
					path: '/pos/clients',
					meta: {
						title: 'Clientes',
					},
					component: () => import('./views/clients'),
				},
				{
					path: '/pos/clients/:id',
					meta: {
						title: 'Clientes / Detalle del Cliente',
					},
					component: () => import('./views/clients/details'),
				},
				{
					path: '/pos/clients/create',
					meta: {
						title: 'Clientes / Registro de Cliente',
					},
					component: () => import('./views/clients/details'),
				},
				// Facturación
				{
					path: '/pos/billing',
					meta: {
						parent: 'Punto de Venta',
						title: 'Facturación',
					},
					component: () => import('./views/billing'),
				},
				// Facturación por ticket
				{
					path: '/pos/billing/:id',
					meta: {
						parent: 'Punto de Venta',
						title: 'Facturación',
					},
					component: () => import('./views/billing'),
				},
				// Salida de productos de almacén
				{
					path: '/warehouse/operations',
					meta: {
						title: 'Inventario / Productos / Operaciones',
					},
					component: () => import('./views/products/outbound'),
				},
				// Control de gastos almacén
				{
					path: '/expense-control',
					meta: {
						title: 'Control de Gastos',
					},
					component: () => import('./views/warehouseExpenseControl'),
				},
				{
					path: '/warehouse/operations/create',
					meta: {
						title: 'Inventario / Productos / Operaciones / Salidas',
					},
					component: () => import('./views/products/outbound/detail'),
				},
				{
					path: '/warehouse/operations/:id',
					meta: {
						title: 'Almacén / Operaciones / Salidas',
					},
					component: () => import('./views/products/outbound/detail'),
				},
				// Usuarios
				{
					path: '/system/users',
					meta: {
						title: 'Administración / Usuarios',
					},
					component: () => import('./views/users'),
				},
				{
					path: '/system/users/create',
					meta: {
						title: 'Administración / Usuarios / Nuevo registro',
					},
					component: () => import('./views/users/create'),
				},
				{
					path: '/system/users/:id',
					meta: {
						title: 'Administración / Usuarios / Detalle de usuarios',
					},
					component: () => import('./views/users/details'),
				},
				{
					path: '/catalogs/business-areas',
					meta: {
						title: 'Administración / Áreas de negocio',
					},
					component: () => import('./views/business-areas'),
				},
				{
					path: '/catalogs/expense-types',
					meta: {
						title: 'Administración / Tipos de gastos',
					},
					component: () => import('./views/expense-types'),
				},
				{
					path: '/system/payment-methods',
					meta: {
						title: 'Administración / Métodos de Pago',
					},
					component: () => import('./views/payment-methods'),
				},
				{
					path: '/catalogs/measurement-units',
					meta: {
						title: 'Administración / Unidades de medida',
					},
					component: () => import('./views/measurement-units'),
				},
				{
					path: '/catalogs/banking-institutions',
					meta: {
						title: 'Administración / Instituciones Bancarias',
					},
					component: () => import('./views/banking-institutions'),
				},
				{
					path: '/system/use-of-cfdi',
					meta: {
						title: 'Administración / Uso de CFDI',
					},
					component: () => import('./views/use-of-cfdi'),
				},
				// Reporte de ventas
				{
					path: '/reports/sales',
					meta: {
						parent: 'Reportes',
						title: 'Ventas',
					},
					component: () => import('./views/reports/sales'),
				},
				//
				{
					path: '/profile',
					meta: {
						title: 'Perfil',
					},
					component: () => import('./views/apps/profile'),
				},

				// Operaciones
				{
					path: '/operations/cash-flow',
					meta: {
						title: 'Cortes',
					},
					component: () => import('./views/cash-flow'),
				},
				{
					path: '/operations/cash-flow/create',
					meta: {
						parent: 'Operación',
						title: 'Cortes',
					},
					component: () => import('./views/cash-flow/insertOrUpdate'),
				},
				{
					path: '/operations/cash-flow/:id',
					meta: {
						title: 'Cortes / Detalle',
					},
					component: () => import('./views/cash-flow/insertOrUpdate'),
				},
				{
					path: '/operations/incomes',
					meta: {
						title: 'Ingresos',
					},
					component: () => import('./views/incomes'),
				},
				{
					path: '/operations/debts-to-pay',
					meta: {
						title: 'Cuentas por Pagar',
					},
					component: () => import('./views/debtsToPay'),
				},
				{
					path: '/operations/debts-to-pay/:id',
					meta: {
						title: 'Cuentas por Pagar / Detalle',
					},
					component: () => import('./views/debtsToPay/details'),
				},
				// Servicio
				{
					path: '/service/meetings',
					meta: {
						title: 'Servicio / Citas de Servicio',
					},
					component: () => import('./views/service/meetings'),
				},
				//
				{
					path: '/catalogs/products-categories',
					meta: {
						title: 'Catálogos / Categorías de Productos',
					},
					component: () => import('./views/products-categories'),
				},
				// Listado de cotizaciones
				{
					path: '/quotations/quotations',
					meta: {
						title: 'Cotizaciones',
					},
					component: () => import('./views/quotations'),
				},
				// Detalle / Generar cotización
				{
					path: '/quotations/:id',
					meta: {
						parent: 'Cotizaciones',
						title: 'Registro',
					},
					component: () => import('./views/quotations/insertOrUpdate'),
				},
				// Listado de citas
				{
					path: '/apointments',
					meta: {
						title: 'Citas',
					},
					component: () => import('./views/apointments'),
				},
				// Detalle / Generar citas
				{
					path: '/apointment/:id',
					meta: {
						parent: 'Citas',
						title: 'Registro',
					},
					component: () => import('./views/apointments/insertOrUpdate'),
				},
				// Dashboard de citas
				{
					path: '/apointments/dashboard',
					meta: {
						parent: 'Citas',
						title: 'Dashboard',
					},
					component: () => import('./views/apointments/dashboard'),
				},
				// Cotizaciones
				{
					path: '/impressment',
					meta: {
						parent: 'Cotizaciones',
						title: 'Dashboard',
					},
					component: () => import('./views/impressment'),
				},
				{
					path: '/impressment/create',
					meta: {
						parent: 'Cotizaciones',
						title: 'Registro',
					},
					component: () => import('./views/impressment/insertOrUpdate'),
				},
				{
					path: '/impressment/:id',
					meta: {
						parent: 'Cotizaciones',
						title: 'Registro',
					},
					component: () => import('./views/impressment/insertOrUpdate'),
				},
				// Leadership
				{
					path: '/leadership',
					meta: {
						parent: 'Dirección',
						title: 'Dashboard',
					},
					component: () => import('./views/leadership'),
				},
				{
					path: '/leadership/operating-expenses',
					meta: {
						parent: 'Dirección',
						title: 'Gastos operativos',
					},
					component: () => import('./views/leadership/operating-expenses'),
				},
				// Administración
				{
					path: '/income-expenses',
					meta: {
						parent: 'Administración',
						title: 'Ingresos y egresos',
					},
					component: () => import('./views/income-expenses'),
				},
				// Catálogo de entradas y salidas de dinero
				{
					path: '/cashflow-catalog',
					meta: {
						parent: 'Catálogos',
						title: 'Ingresos y egresos de dinero',
					},
					component: () => import('./views/cashflowCatalog'),
				},
				// Boutique
				{
					path: '/boutique/products',
					meta: {
						parent: 'Boutique',
						title: 'Productos',
					},
					component: () => import('./views/boutique'),
				},
				{
					path: '/warehouse/boutique/products/:id',
					meta: {
						title: 'Inventario / Productos / Detalle de productos',
					},
					component: () => import('./views/boutique/details'),
				},
				// Cotizador de llantas
				{
					path: '/tires/quoter',
					meta: {
						title: 'Cotizador de Llantas',
					},
					component: () => import('./views/tires-quoter'),
				},
				// SISTEMAS & TI
				{
					path: '/ti',
					meta: {
						title: 'SISTEMAS & TI',
					},
					component: () => import('./views/new/ti'),
				},
				// EQUIPO
				{
					path: '/team',
					meta: {
						title: 'EQUIPO',
					},
					component: () => import('./views/new/team'),
				},
				{
					path: '/team/questionnaires/:id',
					meta: {
						title: 'EVALUACIONES EQUIPO',
					},
					component: () => import('./views/new/team'),
				},
			],
		},

		// System Pages
		{
			path: '/auth',
			component: AuthLayout,
			redirect: 'auth/login',
			children: [
				{
					path: '/auth/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('./views/auth/404'),
				},
				{
					path: '/auth/500',
					meta: {
						title: 'Error 500',
					},
					component: () => import('./views/auth/500'),
				},
				{
					path: '/auth/login',
					meta: {
						title: 'Sign In',
					},
					component: () => import('./views/auth/login'),
				},
				{
					path: '/auth/register',
					meta: {
						title: 'Sign Up',
					},
					component: () => import('./views/auth/register'),
				},
				{
					path: '/auth/forgot-password',
					meta: {
						title: 'Forgot Password',
					},
					component: () => import('./views/auth/forgot-password'),
				},
				{
					path: '/auth/recovery-password',
					meta: {
						title: 'Recuperación de Contraseña',
					},
					component: () => import('./views/auth/recovery-password'),
				},
			],
		},

		// Redirect to 404
		{
			path: '*',
			redirect: 'auth/404',
			hidden: true,
		},
	],
})

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.authRequired)) {
		if (!store.state.user.authorized) {
			next({
				path: '/auth/login',
				query: { redirect: to.fullPath },
			})
		} else {
			//
			// let is_admin = utilities.objectValidate(store, 'state.user.is_admin', false)

			// if (!is_admin) {
			// 	let permissions = utilities.objectValidate(store, 'state.user.permissions', [])
			// 	let menu = Object.values(utilities.objectValidate(store, 'state.users.menu', {}))
			// 	let actualURL = utilities.objectValidate(to, 'fullPath', false)
			// 	let menuKey = false
			// 	let moduleKey = false

			// 	menu.every((menuElements) => {
			// 		// console.log('menuElements', menuElements)
			// 		if (menuElements.length == 1) {
			// 			// console.log('menuElements--->', menuElements[0].menu_title)
			// 			if (menuElements[0].module_url == actualURL) {
			// 				// console.log('menu coincide con ciclo')
			// 				menuKey = menuElements[0].menu_key
			// 				moduleKey = menuElements[0].module_key
			// 				return false
			// 			}
			// 		} else {
			// 			//
			// 			menuElements.every((me) => {
			// 				// console.log('me--->', me)
			// 				if (me.module_url == actualURL) {
			// 					// console.log('menu coincide con ciclo')
			// 					menuKey = me.menu_key
			// 					moduleKey = me.module_key
			// 					return false
			// 				} else {
			// 					return true
			// 				}
			// 			})
			// 		}
			// 		// console.log('moduleKey-->', moduleKey)
			// 		return true
			// 	})

			// 	// console.log({ menuKey, moduleKey, actualURL })

			// 	if ((!menuKey || !moduleKey) && actualURL != '/') {
			// 		if (actualURL != '/dashboard') {
			// 			console.log('redirect')
			// 			next({
			// 				path: '/dashboard',
			// 				query: {},
			// 			})
			// 		}
			// 	} else {
			// 		// console.log({ menuKey, moduleKey, p: permissions[menuKey][moduleKey].r })
			// 		if (!permissions[menuKey][moduleKey].r && actualURL != '/dashboard') {
			// 			next({
			// 				path: '/dashboard',
			// 				query: {},
			// 			})
			// 		}
			// 	}
			// }
			next()
		}
	} else {
		next()
	}
})

export default router
