import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/warehouse-cashflow'
import utilities from '@/services/utilities'
import moment from 'moment'
import _ from 'lodash'
import Swal from 'sweetalert2'

Vue.use(Vuex)
const dateFormat = 'YYYY-MM-DD'

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		filters: {
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
		},
		warehouseCashflowList: [],
		warehouseCashflowBalance: 0,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_ALL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getAll(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						warehouseCashflowList: _.cloneDeep(response.data.elements),
						warehouseCashflowBalance: response.data.balance,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cashflow',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.create(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Acciones de flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						let payload = {
							startDate: moment(state.filters.defaultDate[0]).format(dateFormat),
							endDate: moment(state.filters.defaultDate[1]).format(dateFormat),
						}
						dispatch('GET_ALL', payload)
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		BUDGET_ALLOCATION({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			api
				.budgetAllocation(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Fondeo generado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('direction/GET_DASHBOARD_REPORT', {}, { root: true })
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		filters: (state) => state.filters,
		warehouseCashflowList: (state) => state.warehouseCashflowList,
		warehouseCashflowBalance: (state) => state.warehouseCashflowBalance,
	},
}
