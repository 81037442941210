import Vue from 'vue'
import Vuex from 'vuex'
import * as products from '@/services/products'
import * as facturapi from '@/services/facturapi'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'
import _ from 'lodash'
import numeral from 'numeral'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo Información',
		productsList: [],
		actualProduct: {},
		productCategoriesList: [],
		productSubCategoriesList: [],
		actualProductCategory: {},
		searchSATProductsList: [],
		fullProductCategoriesList: [],
		outboundOrders: [],
		actualOutboundOrder: {},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		SET_STATE({ commit }, payload) {
			commit('SET_STATE', payload)
		},
		GET({ commit, dispatch, rootState }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			products
				.get()
				.then((response) => {
					commit('SET_STATE', {
						productsList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						productsList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE({ commit }, { id, fromBoutique }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			products
				.getOne(id)
				.then((response) => {
					let actualProduct = {
						..._.cloneDeep(response.data),
						unit_cost: numeral(response.data.unit_cost).format('0,0.00'),
					}
					commit('SET_STATE', {
						actualProduct,
					})
					if (fromBoutique) {
						router.push(`/warehouse/boutique/products/${id}`)
					} else {
						router.push(`/warehouse/products/${id}`)
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						actualProduct: {},
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SEARCH({ commit }, { searchText, fromPOS }) {
			//
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			products
				.search({ searchText, fromPOS })
				.then((response) => {
					let data = response.data
					if (data.length) {
						commit('SET_STATE', {
							productsList: _.cloneDeep(data),
						})
					} else {
						Vue.prototype.$notification.error({
							message: 'Productos',
							description: 'No se encontraron resultados de búsqueda',
						})
						commit('SET_STATE', {
							productsList: [],
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						actualProduct: {},
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_BY_SKU({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})

			products
				.updateBySKU(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Producto actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					reject()
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.create(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						commit('SET_STATE', {
							actualProduct: _.cloneDeep(response.data),
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el nuevo registro'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.update(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						commit('SET_STATE', {
							actualProduct: _.cloneDeep(response.data),
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar el nuevo registro'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE({ commit, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			products
				.toggle(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Producto actualizado con éxito.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						let index = state.productsList.findIndex((e) => e.id == payload)
						utilities.objectUpdate(state.productsList[index], 'active', !state.productsList[index].active)
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_PRODUCT_CATEGORIES_LIST({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				products
					.getProductCategories()
					.then((response) => {
						commit('SET_STATE', {
							productCategoriesList: _.cloneDeep(response.data),
						})
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Productos',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar la base de datos'),
						})
						commit('SET_STATE', {
							productCategoriesList: [],
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_PRODUCT_SUBCATEGORIES_LIST({ commit, state }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				fullProductCategoriesList: [],
			})
			return new Promise((resolve, reject) => {
				products
					.getProductSubCategories()
					.then((response) => {
						let data = _.cloneDeep(response.data)
						let fullList = []

						state.productCategoriesList.forEach((category) => {
							let filtered = data.filter((subcategory) => subcategory.category_id == category.id)
							filtered.map((f) => {
								fullList.push({
									...f,
									label: `${f.category_name} --- ${f.subcategory_name}`,
								})
							})
						})

						commit('SET_STATE', {
							productSubCategoriesList: _.cloneDeep(data),
							fullProductCategoriesList: fullList,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Productos',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar la base de datos'),
						})
						commit('SET_STATE', {
							productSubCategoriesList: [],
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE_OUTBOUND_ORDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.createOutboundOrder(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						router.replace(`/almacen`)
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al generar la orden de salida de mercancía.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_OUTBOUND_ORDER({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.updateOutboundOrder(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actuaizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						router.replace(`/warehouse/operations`)
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar la orden de salida de mercancía.'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_OUTBOUND_ORDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				actualOutboundOrder: {},
			})
			let { id, fromWarehouse } = payload
			products
				.getOutboundOrder(id, fromWarehouse)
				.then((response) => {
					commit('SET_STATE', {
						actualOutboundOrder: {
							id: response.data.service_order,
							order: _.cloneDeep(response.data.order),
						},
					})
					if (!utilities.objectValidate(payload, 'onlyLoad', false)) {
						router.replace(`/warehouse/operations/${id}`)
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la orden de salida de mercancía. | GET_OUTBOUND_ORDER'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ACTIVE_OUTBOUND_ORDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			products
				.getOpenOutboundOrders()
				.then((response) => {
					commit('SET_STATE', {
						outboundOrders: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la orden de salida de mercancía. | GET_ACTIVE_OUTBOUND_ORDER'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SEARCH_OUTBOUND_ORDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			products
				.searchOutboundOrder(payload)
				.then((response) => {
					let data = response.data
					if (data.length) {
						commit('SET_STATE', {
							outboundOrders: _.cloneDeep(data),
						})
					} else {
						Vue.prototype.$notification.error({
							message: 'Productos',
							description: 'No se encontraron resultados de búsqueda',
						})
						commit('SET_STATE', {
							outboundOrders: [],
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la orden de salida de mercancía. | SEARCH_OUTBOUND_ORDER'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE_CATEGORY({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.toggleCategory(id)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_CATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		TOGGLE_SUBCATEGORY({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.toggleSubcategory(id)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_SUBCATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE_CATEGORY({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.createCategory(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_CATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE_SUBCATEGORY({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.createSubcategory(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro generado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_SUBCATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_CATEGORY({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.updateCategory(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_CATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE_SUBCATEGORY({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.updateSubcategory(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET_PRODUCT_SUBCATEGORIES_LIST')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SAT_SEARCH_PRODUCTS({ commit }, query) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			facturapi
				.searchProducts(query)
				.then((response) => {
					// console.log('store/products::SAT_SEARCH_PRODUCTS::response-->', response)
					commit('SET_STATE', {
						searchSATProductsList: response,
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_FULL_PRODUCT_CATEGORIES_LIST({ commit, dispatch, state }) {
			if (!state.productCategoriesList.length) {
				dispatch('GET_PRODUCT_CATEGORIES_LIST')
			}
			if (!state.productSubCategoriesList.length) {
				dispatch('GET_PRODUCT_SUBCATEGORIES_LIST')
			}
		},
		async GET_CAT_SUBCAT_LIST({ commit, dispatch, state }) {
			await dispatch('GET_PRODUCT_CATEGORIES_LIST')
			await dispatch('GET_PRODUCT_SUBCATEGORIES_LIST')
		},
		DELETE_IMAGE({ commit, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			products
				.deleteImage(payload)
				.then((response) => {
					Swal.fire({
						title: 'Productos',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado de manera correcta.'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						commit('SET_STATE', {
							actualProduct: {
								..._.cloneDeep(state.actualProduct),
								images: response.data,
							},
						})
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Productos',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		productsList: (state) => state.productsList,
		actualProduct: (state) => state.actualProduct,
		productCategoriesList: (state) => state.productCategoriesList,
		productSubCategoriesList: (state) => state.productSubCategoriesList,
		actualProductCategory: (state) => state.actualProductCategory,
		searchSATProductsList: (state) => state.searchSATProductsList,
		fullProductCategoriesList: (state) => state.fullProductCategoriesList,
		outboundOrders: (state) => state.outboundOrders,
		actualOutboundOrder: (state) => state.actualOutboundOrder,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
