import Vue from 'vue'
import Vuex from 'vuex'
import * as priceTab from '@/services/price-tab'
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		priceTabList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit }, globalLoader) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			priceTab
				.get()
				.then((response) => {
					commit('SET_STATE', {
						priceTabList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Tabulador de precios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
				})
				.finally(() => {
					if (globalLoader) {
						commit(
							'SET_STATE',
							{
								spinnerLoader: false,
							},
							{ root: true },
						)
					} else {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					}
				})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				return priceTab
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Tabulador de precios',
							description: response.message,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Tabulador de precios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			priceTab
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Tabulador de precios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Tabulador de precios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			priceTab
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Tabulador de precios',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Tabulador de precios',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		UPDATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				return priceTab
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Tabulador de precios',
							description: response.message,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Tabulador de precios',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		SEARCH({ commit }, { payload, globalLoader }) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			priceTab
				.search(payload)
				.then((response) => {
					commit('SET_STATE', {
						priceTabList: _.cloneDeep(utilities.objectValidate(response, 'data', [])),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Administración de servicios',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener los registros'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					if (globalLoader) {
						commit(
							'SET_STATE',
							{
								spinnerLoader: false,
							},
							{ root: true },
						)
					} else {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					}
				})
		},
	},
	getters: {
		priceTabList: (state) => state.priceTabList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
