import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/pos'
import utilities from '@/services/utilities'
import moment from 'moment'
import numeral from 'numeral'
import * as facturapi from '@/services/facturapi'

const dateFormat = 'YYYY-MM-DD'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		filters: {
			defaultDate: [moment().format(dateFormat), moment().format(dateFormat)],
			cashier: null,
			paymentMethod: null,
			ticket: '',
		},
		ticketList: [],
		advancesList: [],
		totals: {
			service: 0,
			boutique: 0,
			spareParts: 0,
			advances: 0,
		},
		bankTerminalOperations: {
			panel: {
				credit: {
					total: 0,
				},
				debit: {
					total: 0,
				},
			},
			pacific: {
				credit: {
					total: 0,
				},
				debit: {
					total: 0,
				},
				paymentMethods: {
					cash: {
						total: 0,
					},
					transfer: {
						total: 0,
					},
					paypal: {
						total: 0,
					},
					mercadopago: {
						total: 0,
					},
				},
			},
		},
		actualTicket: {},
		earnings: 0,
		salesCosts: 0,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		RESET_ONE_FILTER({ commit, dispatch, state }, key) {
			let filters = {
				...state.filters,
			}

			if (key == 'defaultDate') {
				filters.defaultDate = [moment().format(dateFormat), moment().format(dateFormat)]
			} else {
				filters[key] = 'Todos'
			}

			commit('SET_STATE', {
				filters,
			})
		},
		GET_TICKETS({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
				ticketList: [],
				advancesList: [],
				totals: {},
				bankTerminalOperations: {
					panel: {
						credit: {
							total: 0,
						},
						debit: {
							total: 0,
						},
					},
					pacific: {
						credit: {
							total: 0,
						},
						debit: {
							total: 0,
						},
						paymentMethods: {
							cash: {
								total: 0,
							},
							transfer: {
								total: 0,
							},
							paypal: {
								total: 0,
							},
							mercadopago: {
								total: 0,
							},
						},
					},
				},
			})
			api
				.getTickets(payload)
				.then((response) => {
					commit('SET_STATE', {
						ticketList: _.cloneDeep(response.data.tickets),
					})

					if (utilities.objectValidate(response, 'data.advances', false)) {
						commit('SET_STATE', {
							advancesList: _.cloneDeep(response.data.advances),
						})
					}

					if (utilities.objectValidate(response, 'data.totals', false)) {
						commit('SET_STATE', {
							totals: _.cloneDeep(response.data.totals),
						})
					}

					if (utilities.objectValidate(response, 'data.bankTerminalOperations', false)) {
						commit('SET_STATE', {
							bankTerminalOperations: _.cloneDeep(response.data.bankTerminalOperations),
						})
					}
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Ordenes de Compra',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_ONE_TICKET({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			api
				.getOneTicket(id)
				.then((response) => {
					commit('SET_STATE', {
						actualTicket: _.cloneDeep(response.data),
					})
					// router.push(`/pos/billing/${id}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Ordenes de Compra',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DOWNLOAD_INVOICE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			facturapi
				.downloadPDF(id)
				.then((response) => {
					window.open(`${process.env.VUE_APP_API_URL}/${response.data}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la factura'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		REQUEST_AUTHORIZATION({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Confirmando...',
			})
			return new Promise((resolve, reject) => {
				api
					.requestAuthorization(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(utilities.objectValidate(error, 'response.data.message', 'Contraseña inválida.'))
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		filters: (state) => state.filters,
		ticketList: (state) => state.ticketList,
		actualTicket: (state) => state.actualTicket,
		advancesList: (state) => state.advancesList,
		bankTerminalOperations: (state) => state.bankTerminalOperations,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		earnings: (state) => {
			let total = 0
			state.ticketList.map((e) => {
				let cart = JSON.parse(e.cart)
				cart.map((el) => {
					total += (numeral(el.product.normal_price).value() - numeral(el.product.unit_cost).value()) * el.quantity
				})
			})
			return total
		},
		salesCosts: (state) => {
			let total = 0
			state.ticketList.map((e) => {
				let cart = JSON.parse(e.cart)
				cart.map((el) => {
					// sólo se considera en la sumatoria el valor de productos y no de servicios
					if (el.product.measurement_unit == 'H87') {
						total += numeral(el.product.unit_cost).value() * el.quantity
					}
				})
			})
			return total
		},
		totals: (state) => state.totals,
	},
}
