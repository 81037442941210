<template>
	<a-dropdown :trigger="['click']" placement="bottomLeft">
		<div :class="$style.dropdown">
			<a-avatar shape="square" :src="avatarImage" size="large" :class="$style.avatar" />
		</div>
		<a-menu slot="overlay">
			<a-menu-item>
				<div>
					<strong>Hola {{ user.name }}</strong>
				</div>
				<div>
					<strong class="mr-1">Rol:</strong>
					{{ user.role || '—' }}
				</div>
			</a-menu-item>
			<a-menu-divider />
			<a-menu-item>
				<div>
					<strong class="mr-1">Email:</strong>
					{{ user.email || '—' }}
				</div>
				<div>
					<strong class="mr-1">Teléfono:</strong>
					{{ user.mobile_number || '—' }}
				</div>
			</a-menu-item>
			<a-menu-divider />
			<a-menu-item>
				<router-link to="/profile"><i class="fe fe-user mr-2"></i> Editar perfil</router-link>
			</a-menu-item>
			<a-menu-divider />
			<a-menu-item>
				<a href="javascript: void(0);" @click="logout">
					<i class="fe fe-log-out mr-2"></i>
					Cerrar sesión
				</a>
			</a-menu-item>
		</a-menu>
	</a-dropdown>
</template>

<script>
import { mapState } from 'vuex'

export default {
	computed: {
		...mapState(['user']),
		fullName() {
			return `${this.user.name} ${this.user.first_lastname} ${this.user.second_lastname || ''}`
		},
		avatarImage() {
			return this.user.avatar_image ? `${process.env.VUE_APP_API_URL}${this.user.avatar_image}` : 'resources/images/avatar.png'
		},
		loading() {
			return this.$store.state.user.loading
		},
	},
	data: function () {
		return {
			count: 7,
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('user/LOGOUT')
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
