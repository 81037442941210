import Vue from 'vue'
import Vuex from 'vuex'
//
import * as directionAPI from '@/services/direction'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información',
		dashboardReport: {
			outboundByArea: {
				general: 0,
			},
			totalCosts: {
				spareParts: 0,
			},
			totalInbound: 0,
			percent: 0,
			groupData: {
				labels: ['Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'],
				series: [
					{
						data: [0, 0, 0, 0, 0, 0],
					},
				],
			},
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_DASHBOARD_REPORT({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			directionAPI
				.getDashboardReport(payload)
				.then((response) => {
					commit('SET_STATE', {
						dashboardReport: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					console.log(error)
					Swal.fire({
						title: 'Facturación',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		dashboardReport: (state) => state.dashboardReport,
	},
}
